import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS, COLORS } from '../../../constants'

export const styles = makeStyles(() => ({
  screenContainer: {
    paddingBottom: '60px',
  },
  tabContent: {
    maxWidth: '508px',
    margin: '0 auto',
  },
  loadingScreenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  logo: {
    width: '26px',
    height: '28px',
  },
  textLogo: {
    width: '136px',
    height: '35px',
  },
  screenWrapper: {
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    paddingRight: '20px',
    textAlign: 'center',
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  callToAction: {
    paddingBottom: '20px',
  },
  slideTitle: {
    fontSize: '28px',
    lineHeight: '30.8px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    marginTop: '20px',
    marginBottom: '10px',
    maxWidth: '330px',
  },
  slideText: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    margin: 0,
    maxWidth: '345px',
  },
  button: {
    backgroundColor: `${COLORS.lightTeal} !important`,
    borderColor: COLORS.lightTeal,
    border: '1px solid #000000 !important',
    maxHeight: '40px',
    maxWidth: '200px',
    marginBottom: '20px',
  },
  buttonText: {
    color: '#000000 !important',
    fontSize: '13px',
  },
  media: {
    maxWidth: '350px',
    maxHeight: '350px',
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: '230px',
      maxHeight: '230px',
    },
  },
  skip: {
    textDecoration: 'underline',
    fontSize: '16px',
  },
  tabLabel: {
    fontSize: '10px !important',
  },
  userImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
  },
  header: {
    padding: '20px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #000000',
  },
  headerText: {
    fontSize: '18px',
  },
  hidden: {
    display: 'none',
  },
}))

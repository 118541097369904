import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS, COLORS } from '../../../constants'

export const styles = makeStyles(() => ({
  topBrandsContainer: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  topBrandsTitle: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    marginTop: '20px',
    marginBottom: '5px',
  },
  topBrandsItems: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  topBrandItem: {
    textDecoration: 'none',
    padding: '8px',
    textAlign: 'center',
  },
  topBrandImage: {
    maxWidth: '90px',
    height: '60px',
  },
  topBrandCashBack: {
    fontSize: '12px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    marginTop: '5px',
    marginBottom: 0,
    color: '#175972',
  },

  button: {
    backgroundColor: '#FFF !important',
    borderColor: '#000',
    border: '1px solid #000000 !important',
    maxHeight: '34px',
    maxWidth: '93px',
    margin: 0,
  },
  buttonText: {
    color: '#000000 !important',
    fontSize: '13px',
  },
  shopItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  brandName: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '12px',
    fontWeight: 500,
    margin: 0,
  },
  cashBack: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '10px',
    fontWeight: 400,
    color: '#175972',
    margin: 0,
  },
  shopLink: {
    padding: '10px 30px',
    border: '1px solid #000000 !important',
    textDecoration: 'none',
    color: '#000',
    fontSize: '13px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
  },
  howItWorksText: {
    fontSize: '12px',
    fontFamily: FONTS.Graphik.GraphikLight,
    fontWeight: 400,
    margin: '10px 0',
  },
  hoItWorkButton: {
    backgroundColor: `${COLORS.lightTeal} !important`,
    borderColor: COLORS.lightTeal,
    border: '1px solid #000000 !important',
    maxHeight: '40px',
    maxWidth: '335px',
    marginBottom: '20px',
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: '100%',
    },
  },
}))

import * as React from 'react'
import { styles } from './styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'

interface ButtonProps {
    onClick: Function
    icon?: any
    label: string
    isSubmitting?: boolean
    disabled?: boolean
    customIconStyle: any
    customButtonStyle?:any
}

const SignInButton = ({
    onClick,
    icon,
    label,
    isSubmitting,
    disabled,
    customIconStyle,
    customButtonStyle
}: ButtonProps) => {
    const classes = styles()
    const buttonClass = classNames({
        [classes.button]: true,
        [classes.disabled]: disabled,
    })

    return (
        <button
            type="button"
            className={buttonClass}
            onClick={() => onClick()}
            style={customButtonStyle}
            disabled={disabled}
        >
            {icon && <img src={icon} className={classes.icon} style={customIconStyle} alt="buttonIcon" />}
            {isSubmitting ? (
                <CircularProgress color="secondary" size={20} />
            ) : (
                <Typography variant="button" component="span" className={classes.buttonText} >
                    {label}   
                </Typography>
            )}
        </button>
    )
}

export default SignInButton

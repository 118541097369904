const shareSocialLinks = {
  sms: 'sms:?&body={{title}}{{url}}',
  mail: 'mailto:?body={{title}}{{url}}&subject={{subject}}',
  facebook:
    'https://www.facebook.com/sharer/sharer.php?u={{url}}&quote={{title}}',
  twitter: 'https://twitter.com/share?url={{url}}&title={{title}}',
  pinterest:
    'http://www.pinterest.com/pin/create/button/?url={{url}}&media={{media}}&description={{description}}',
  pinterestHyperLink: 'http://www.pinterest.com/pin/create/link/?url={{url}}',
}

const addUrlProtocol = (url: string) => {
  if (!url.startsWith('http')) {
    url = `https://${url}`
  }

  return url
}

const shareLinkVariables = {
  url: '{{url}}',
  media: '{{media}}',
  description: '{{description}}',
  title: '{{title}}',
  subject: '{{subject}}',
}

export const generateFacebookSharelink = (url: string, title: string) => {
  return shareSocialLinks.facebook
    .replace(shareLinkVariables.url, addUrlProtocol(url))
    .replace(shareLinkVariables.title, title)
}

export const generateTwitterSharelink = (url: string, title: string) => {
  return shareSocialLinks.twitter
    .replace(shareLinkVariables.url, addUrlProtocol(url))
    .replace(shareLinkVariables.title, title)
}

export const generateSmsSharelink = (url: string, title: string) => {
  return shareSocialLinks.sms
    .replace(shareLinkVariables.url, addUrlProtocol(url))
    .replace(shareLinkVariables.title, title)
}

export const generateEmailSharelink = (
  url: string,
  title: string,
  subject: string
) => {
  return shareSocialLinks.mail
    .replace(shareLinkVariables.url, addUrlProtocol(url))
    .replace(shareLinkVariables.title, title)
    .replace(shareLinkVariables.subject, subject)
}

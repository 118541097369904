import React, { useRef, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { Amplify } from '@aws-amplify/core'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import Dialog from '@material-ui/core/Dialog'
import ReCAPTCHA from 'react-google-recaptcha'

import emailIcon from '../../../assets/images/mail.svg'
import googleIcon from '../../../assets/images/google-coloured.svg'
import appleIcon from '../../../assets/images/apple.svg'
import closeMenuIcon from '../../../assets/images/close.svg'
import { styles } from './styles'
import awsConfig from '../../../aws-exports'
import chrLogo from '../../../assets/images/chirpyest.svg'
import chirpyestTextLogo from '../../../assets/images/chirpyest-text-logo.svg'
import {
  AppBar,
  Box,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core'
import useSignIn from '../../../hooks/useSignIn'
import SignInButton from '../signInButton'
import { COLORS } from '../../../constants'
import SignInInput from '../signInInput'
import { checkIfSubmittable } from '../../../../utils/validation'

// Auth Configs
awsConfig.Auth.oauth.redirectSignOut =
  process.env.GATSBY_OAUTH_REDIRECT_SIGN_OUT_APP
awsConfig.Auth.oauth.redirectSignIn =
  process.env.GATSBY_OAUTH_REDIRECT_SIGN_IN_APP

Amplify.configure(awsConfig)
Auth.configure(awsConfig)

// Types Declarations
interface ISignInProps {
  handleClose: any
  openStatus: boolean
}

const SignIn = ({ handleClose, openStatus }: ISignInProps) => {
  const classes = styles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hasAccount, setHasAccount] = useState(false)
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)

  const [formErrors, setFormErrors] = useState({
    emailMessage: '',
    passwordMessage: '',
  })

  const reRef = useRef<ReCAPTCHA>(null)

  const {
    isSubmitting,
    requestError,
    handleEmailSignIn,
    handleGoogleSignIn,
    handleAppleSignIn,
  } = useSignIn(email, password, setFormErrors, captchaVerified)

  const eye = <VisibilityOutlinedIcon className={classes.eyeIcon} />
  const eyeSlash = <VisibilityOffOutlinedIcon className={classes.eyeIcon} />

  const buttonProps = [
    {
      onClick: handleGoogleSignIn,
      label: hasAccount ? 'sign in with google' : 'join with google',
      icon: googleIcon,
      customIconStyle: { width: '21.059px', height: '21.586px' },
    },
    {
      onClick: handleAppleSignIn,
      label: hasAccount ? 'sign in with apple' : 'join with apple',
      icon: appleIcon,
      customIconStyle: { width: '21px', height: '24.419px' },
    },
    {
      onClick: handleEmailSignIn,
      label: hasAccount ? 'sign in with email' : 'join with email',
      icon: emailIcon,
      customButtonStyle: { backgroundColor: COLORS.lightTeal },
      customIconStyle: { width: '16px', height: '13.091px' },
    },
  ]
  console.log('buttonProps', buttonProps)
  console.log('hasAccount', hasAccount)

  // Customize buttons order display based on user status
  const signInButtons = hasAccount
    ? [
        buttonProps[2],
        { label: 'or', isText: true },
        ...buttonProps.slice(0, 2),
      ]
    : buttonProps

  // TODO: Hashem --> fix the onChange function
  const onChange = async (token: string | null) => {
    // TO REVERT
    if (token === null) {
      reRef?.current?.reset()
      setCaptchaVerified(false)
    } else {
      setCaptchaVerified(true)
    }
  }

  return (
    <Dialog
      fullScreen={true}
      open={openStatus}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {/* Dialog Header */}
      <DialogTitle className={classes.appBar}>
        <Box className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <img src={closeMenuIcon} alt="close" />
          </IconButton>
          <img
            src={chirpyestTextLogo}
            className={classes.homeHeaderLogo}
            alt="chirpyest-text-logo"
          />
          <img src={chrLogo} className={classes.logo} />
        </Box>
      </DialogTitle>

      <Box component="section" className={classes.dialogContent}>
        {/* Title & Description */}
        <Typography variant="h2" className={classes.title}>
          {hasAccount ? 'sign in' : 'join chirpyest'}
        </Typography>
        {!hasAccount && (
          <Typography variant="subtitle1" className={classes.text}>
            get cash back from 1000s of retailers and top brands. join to shop,
            share and earn $$!
          </Typography>
        )}

        {/* User credentials */}
        {hasAccount && (
          <Box className={classes.credentials}>
            <div className={classes.credentialBox}>
              {formErrors.emailMessage && (
                <p className={classes.errorMessage}>
                  {formErrors.emailMessage}
                </p>
              )}
              <SignInInput
                value={email}
                onChange={(e: {
                  target: { value: React.SetStateAction<string> }
                }) => {
                  setEmail(e.target.value)
                }}
                placeholder="email address"
                type="email"
                error={!!formErrors.emailMessage}
                onKeyPress={(event: any) =>
                  !isSubmitting && checkIfSubmittable(event, handleEmailSignIn)
                }
              />
            </div>
            <div className={classes.credentialBox}>
              {formErrors.passwordMessage && (
                <p className={classes.errorMessage}>
                  {formErrors.passwordMessage}
                </p>
              )}
              <div className={classes.passwordField}>
                <SignInInput
                  value={password}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> }
                  }) => {
                    setPassword(e.target.value)
                  }}
                  placeholder="password"
                  type={passwordShown ? 'text' : 'password'}
                  error={!!formErrors.passwordMessage}
                  onKeyPress={(event: any) =>
                    !isSubmitting &&
                    checkIfSubmittable(event, handleEmailSignIn)
                  }
                />
                <div
                  className={classes.passVisibility}
                  onClick={() => setPasswordShown(!passwordShown)}
                >
                  {passwordShown ? eye : eyeSlash}
                </div>
              </div>
            </div>
            <Typography variant="subtitle1" className={classes.forgotPassword}>
              forgot password?{' '}
              <a
                href="https://www.chirpyest.com/forgot-password"
                className={classes.forgotPassword}
                rel="noreferrer"
                target="_blank"
              >
                tap here{' '}
              </a>
            </Typography>
            <div
              className={classes.recaptchaContainer}
              style={{ transform: 'scale(0.83)', transformOrigin: '0 0' }}
            >
              <ReCAPTCHA
                sitekey="6LexZ3olAAAAAG_dB8FE1zj9wYuq8NmYWI3KqJss" //"6LexZ3olAAAAAG_dB8FE1zj9wYuq8NmYWI3KqJss" // {`${process.env.GATSBY_RECAPTCHA_SECRET_KEY}`}
                onChange={onChange}
                ref={reRef}
              />
            </div>
          </Box>
        )}

        {/* Sign in Buttons */}
        {requestError && <p className={classes.errorMessage}>{requestError}</p>}
        <Box>
          {signInButtons.map((button: any, index) =>
            button?.isText ? (
              <span key={index}>{button.label}</span>
            ) : (
              <SignInButton
                key={index}
                onClick={button.onClick}
                label={button.label}
                icon={button.icon}
                isSubmitting={isSubmitting}
                customIconStyle={button.customIconStyle}
                customButtonStyle={button.customButtonStyle}
                disabled={isSubmitting}
              />
            )
          )}
        </Box>

        <Typography variant="subtitle1" className={classes.text}>
          {hasAccount ? 'no account? ' : 'already a member? '}
          <span
            className={classes.loginButton}
            onClick={() => setHasAccount(prev => !prev)}
          >
            {hasAccount ? 'join here' : 'Log in'}
          </span>
        </Typography>
      </Box>
    </Dialog>
  )
}

export default SignIn

import React, { useState, useEffect } from 'react'
import Auth from '@aws-amplify/auth'
import {
  BottomNavigation,
  BottomNavigationAction,
  Typography,
} from '@material-ui/core'
import SEO from '../seo'
import Brands from './brandsApp'
import ShareLink from './shareLinkApp'
import CommunityApp from './communityApp'
import AccountApp from './accountApp'
import BlogApp from './blogApp'
import HomeApp from './homeApp'
import CHRSpinner from '../spinner'
import axios from 'axios'
import CHRaxios from '../../axios'
import { COLORS, ENDPOINTS, baseURL } from '../../constants'
import { getBrandsLogosURLSrc } from '../../../utils/brandsHelper'
import { trendingBrandsLogos } from '../../../utils/trendingBrandsLogos'
import { useAppState } from '../../appState'
import home from '../../assets/images/app-nav-home.svg'
import brandsIcon from '../../assets/images/app-nav-search.svg'
import share from '../../assets/images/app-nav-share-2.svg'
import users from '../../assets/images/app-nav-users.svg'
import blog from '../../assets/images/app-nav-blog.svg'
import chrLogo from '../../assets/images/chirpyest.svg'
import chirpyestTextLogo from '../../assets/images/chirpyest-text-logo.svg'
import birdAvatar from '../../assets/images/bird-avatar.png'
import { styles } from './styles'

const Tab = ({ children, ...props }) => {
  return (
    <BottomNavigationAction
      style={{
        padding: 0,
        borderTop: props.isSelected
          ? '2px solid #000'
          : `2px solid ${COLORS.lightTeal}`,
      }}
      {...props}
    >
      {children}
    </BottomNavigationAction>
  )
}

function App() {
  const classes = styles()
  const [appState] = useAppState()
  const [selected, setSelected] = useState(0)
  const [shareData, setShareData] = useState({})
  const [boardItems, setBoardItems] = useState({
    isLoading: true,
    boardItems: [],
  })
  const [blogs, setBlogs] = useState({
    isLoading: true,
    info: [],
  })
  const [brandsList, setBrandsList] = useState({
    brandsListData: [],
    isBrandsListHere: false,
    isBrandsListLoading: true,
  })
  const [usersResults, setUsersResults] = useState({
    data: [],
    finishedLoading: true,
    offset: 0,
    size: 30,
  })
  const [isCommunityLoading, setIsCommunityLoading] = useState(true)
  const [featured, setFeatured] = useState({})
  const [topBrands, setTopBrands] = useState([])
  const [userId, setUserId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const howItWorksStatus = window.localStorage.getItem('howItWorksStatus')
    if (!howItWorksStatus)
      window.localStorage.setItem('howItWorksStatus', 'skipped')
  }, [])

  useEffect(() => {
    const userName = appState.username
    const boardEndpoint = ENDPOINTS.shoppingBoardNonUser.replace(
      ':id',
      userName
    )
    Promise.all([
      axios.get(`${baseURL}${ENDPOINTS.brandsList}?website=1`),
      axios.get(`${baseURL}${ENDPOINTS.featuredBrands}`),
      CHRaxios(boardEndpoint),
      axios.get(`${baseURL}${ENDPOINTS.blogs}?top=10`),
    ])
      .then(
        ([brandsResponse, featuredResponse, boardResponse, blogsResponse]) => {
          const { data } = brandsResponse.data
          setBrandsList({
            brandsListData: data,
            isBrandsListHere: true,
            isBrandsListLoading: false,
          })
          setFeatured(
            featuredResponse.data.data.brands.reduce((acc, v) => {
              acc[v.brand_id] = v.brand_name
              return acc
            }, {})
          )
          const filteredBrands = data.filter((item: any) => item.isTrending)
          const filteredTrendingBrands = getBrandsLogosURLSrc(
            filteredBrands,
            trendingBrandsLogos
          )
          setTopBrands(filteredTrendingBrands)
          const {
            data: {
              data: { shoppingBoardItems },
            },
          } = boardResponse
          setBoardItems({
            isLoading: false,
            boardItems: shoppingBoardItems,
          })
          setBlogs({
            isLoading: false,
            info: blogsResponse.data.data,
          })
          setIsLoading(false)
          return Auth.currentUserInfo()
        }
      )
      .then(currentUserInfo => {
        if (currentUserInfo)
          setUserId(currentUserInfo.attributes['custom:user_id'])
      })
      .catch(error => {
        setBrandsList({
          brandsListData: [],
          isBrandsListHere: false,
          isBrandsListLoading: false,
        })
        return error
      })
    const prevState = sessionStorage.getItem('the-chirpyest')
    if (prevState) {
      setUsersResults(JSON.parse(prevState))
      setIsCommunityLoading(false)
      sessionStorage.removeItem('the-chirpyest')

      const scrollPos = sessionStorage.getItem('the-chirpyest-scroll-pos')
      setTimeout(() => window.scrollTo(0, Number(scrollPos)), 100)
      sessionStorage.removeItem('the-chirpyest-scroll-pos')
    } else {
      getUsersResults()
    }
  }, [appState.userId])

  const getUsersResults = async () => {
    const {
      data: { data: results },
    } = await CHRaxios.get(`${ENDPOINTS.getTheChirpyest}?offset=0&size=30`)
    setUsersResults({
      finishedLoading:
        results.length === 0 || results.length < usersResults.size,
      offset: usersResults.offset + usersResults.size,
      size: 30,
      data: usersResults.data.concat(results.sort(() => 0.5 - Math.random())),
    })
    setIsCommunityLoading(false)
  }

  if (isLoading) return <CHRSpinner />

  return (
    <>
      <SEO title="Chirpyest App" />
      <div className={classes.screenContainer}>
        <div className={classes.header}>
          <div onClick={() => setSelected(5)} className={classes.imageWrapper}>
            <img
              src={appState.userProfile.imageUrl || birdAvatar}
              className={classes.userImage}
            />
          </div>
          {selected === 0 && (
            <Typography variant="body1" className={classes.headerText}>
              <img
                src={chirpyestTextLogo}
                className={classes.homeHeaderLogo}
                alt="chirpyest-text-logo"
              />
            </Typography>
          )}
          {selected === 1 && (
            <Typography variant="body1" className={classes.headerText}>
              retailers
            </Typography>
          )}
          {selected === 2 && (
            <Typography variant="body1" className={classes.headerText}>
              share + earn
            </Typography>
          )}
          {selected === 3 && (
            <Typography variant="body1" className={classes.headerText}>
              community
            </Typography>
          )}
          {selected === 4 && (
            <Typography variant="body1" className={classes.headerText}>
              blog
            </Typography>
          )}
          {selected === 5 && (
            <Typography variant="body1" className={classes.headerText}>
              account
            </Typography>
          )}
          <img src={chrLogo} className={classes.logo} />
        </div>
        <div>
          <div className={classes.tabContent}>
            {selected === 0 && <HomeApp trendingBrands={topBrands} />}
            {selected === 1 && (
              <Brands
                brandsList={brandsList}
                featured={featured}
                topBrands={topBrands}
                userId={userId}
              />
            )}
            {selected === 2 && (
              <ShareLink
                boardItems={boardItems}
                shareData={shareData}
                handleShareData={setShareData}
              />
            )}
            {selected === 3 && (
              <CommunityApp
                isCommunityLoading={isCommunityLoading}
                communityInfo={usersResults}
              />
            )}
            {selected === 4 && <BlogApp blogsInfo={blogs} />}
            {selected === 5 && <AccountApp />}
          </div>
        </div>
        <BottomNavigation
          value={selected}
          onChange={(value, newValue) => {
            setSelected(newValue)
          }}
          className={classes.bottomNav}
          showLabels
        >
          <Tab
            isSelected={selected === 0}
            label={
              <Typography variant="body1" className={classes.tabLabel}>
                home
              </Typography>
            }
            icon={<img src={home} />}
          >
            Home
          </Tab>
          <Tab
            isSelected={selected === 1}
            label={
              <Typography variant="body1" className={classes.tabLabel}>
                brands
              </Typography>
            }
            icon={<img src={brandsIcon} />}
          >
            Brands
          </Tab>
          <Tab
            isSelected={selected === 2}
            label={
              <Typography variant="body1" className={classes.tabLabel}>
                sharing tools
              </Typography>
            }
            icon={<img src={share} />}
          >
            Sharing Tools
          </Tab>
          <Tab
            isSelected={selected === 3}
            label={
              <Typography variant="body1" className={classes.tabLabel}>
                community
              </Typography>
            }
            icon={<img src={users} />}
          >
            Community
          </Tab>
          <Tab
            isSelected={selected === 4}
            label={
              <Typography variant="body1" className={classes.tabLabel}>
                blog
              </Typography>
            }
            icon={<img src={blog} />}
          >
            Blog
          </Tab>
        </BottomNavigation>
      </div>
    </>
  )
}

export default App

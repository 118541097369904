import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(() => ({
  screenContainer: {
    paddingBottom: '60px',
  },
  tabContent: {
    maxWidth: '508px',
    margin: '0 auto',
  },
  bottomNav: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '66px',
  },
  logo: {
    width: '26px',
    height: '28px',
  },

  tabLabel: {
    fontSize: '10px !important',
  },
  userImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
  },
  header: {
    padding: '20px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #000000',
  },
  headerText: {
    fontSize: '18px',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  homeHeaderLogo: {
    width: '136px',
    height: '35px',
  }
}))

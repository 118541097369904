import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  icon: {
    position: 'absolute',
    left: 20,
  },
  button: {
    padding: 0,
    fontFamily: FONTS.Graphik.GraphikMedium,
    letterSpacing: 1.2,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    height: 40,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.common.black}`,
    margin: '5px auto',
    width: '250px',
    '& p': {
      color: theme.palette.common.black,
    },
  },
  buttonText: {
    fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.156px',
    textTransform: 'lowercase',
  },
  disabled: {
    background: COLORS.ashGrey,
    border: 'none',
    pointerEvents: 'none',
  },
}))

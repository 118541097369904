import React, { useState } from 'react'
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { Amplify } from '@aws-amplify/core'
import { navigate } from 'gatsby'
import ReCAPTCHA from 'react-google-recaptcha'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { signInValidation } from '../../utils/validation'
import { useAppState } from '../appState'
import axios from '../axios'
import { EXTENSION_ID, ENDPOINTS } from '../constants'
import CHRButton from '../components/button'
import CHRInput from '../components/input'
import CHRText from '../components/typography'
import { checkIfSubmittable } from '../../utils/validation'
import { sendExtensionMessage } from '../../utils/extensionHelper'
import { TrackingCase } from '../../utils/trackingCases'
import { tracker } from '../systemLogs'
import emailIcon from '../assets/images/email.svg'
import googleIcon from '../assets/images/google_logo.svg'
import appleIcon from '../assets/images/apple_logo.svg'
import closeMenuIcon from '../assets/images/close.svg'
import awsConfig from '../aws-exports'
import chrLogo from '../assets/images/chirpyest.svg'
import chirpyestTextLogo from '../assets/images/chirpyest-text-logo.svg'
import { AppBar, Box, IconButton, Toolbar, Typography } from '@material-ui/core'

declare const window: any

const useSignIn = (
  email: string,
  password: string,
  setFormErrors: any,
  captchaVerified: boolean
) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [requestError, setRequestError] = useState('')
  // @ts-ignore
  const [, dispatch] = useAppState()

  const isCaptchaVerified = () => {
    if (captchaVerified === false) {
      setRequestError('Please verify captcha!')
      return false
    }
    return captchaVerified
  }

  const handleSuccessfulSignIn = async (user: any) => {
    const {
      data: { data },
    } = await axios.get(
      ENDPOINTS.user.replace(':id', user.attributes['custom:user_id'])
    )

    const currentUserInfo = await Auth.currentCredentials()
    const session = await Auth.currentSession()
    const userProfile = {
      email: data.email,
      userName: data.username,
      fullName: data.name,
      imageKey: data.image,
      imageUrl: '' as Object | string,
      isPro: data.isPro,
      isCreator: data.isCreator,
      isReferralBonus: data.isReferralBonus,
      userDoublePayment: data.userDoublePayment,
      financialData: data.financialData || {
        pending: '0',
        lastClosedOut: '0',
        receivableMilestone: '0',
      },
      loginProvider: data.loginProvider,
    }

    if (data.image && currentUserInfo?.identityId) {
      const url = `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${data.image}`
      userProfile.imageUrl = url
    }

    sendExtensionMessage({ session })
    if (window.chrome?.runtime?.sendMessage) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        { session },
        (_response: any) => {}
      )
    }

    dispatch({ type: 'SIGN_IN', auth: user, userProfile })
    navigate('/app/user')
  }

  const handleUnauthorizedSignIn = () => {
    setRequestError('not authorized')
    Auth.signOut()
    sendExtensionMessage({ action: 'signOut' })
    if (window.chrome?.runtime?.sendMessage) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        { action: 'signOut' },
        (_response: any) => {}
      )
    }
  }

  const handleSignInError = (error: any) => {
    if (error.errors) {
      if (error.inner[0]?.params.path === 'email') {
        setFormErrors({
          emailMessage: error.inner[0]?.message,
          passwordMessage: error.inner[1]?.message,
        })
      } else {
        setFormErrors({ passwordMessage: error.inner[0]?.message })
      }
      return
    }
    setRequestError('incorrect email or password')
    tracker.track(TrackingCase.UserTracking, `login failed`, {
      errorMessage: error.message,
      email,
      username: email,
    })
    // Logic to handle sign-in errors
  }

  const handleSignIn = async (username: string, password: string) => {
    setIsSubmitting(true)
    setFormErrors({ emailMessage: '', passwordMessage: '' })

    if (!isCaptchaVerified()) return

    try {
      await signInValidation({ email, password })
      const user = await Auth.signIn(username, password)

      if (user.signInUserSession.accessToken) {
        await handleSuccessfulSignIn(user)
      } else if (user.signInUserSession.accessToken) {
        handleUnauthorizedSignIn()
      }
    } catch (error) {
      handleSignInError(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleEmailSignIn = () => handleSignIn(email, password)
  const handleGoogleSignIn = () => {
    /* Google sign-in logic */
  }
  const handleAppleSignIn = () => {
    /* Apple sign-in logic */
  }

  return {
    isSubmitting,
    requestError,
    handleEmailSignIn,
    handleGoogleSignIn,
    handleAppleSignIn,
  }
}

export default useSignIn

import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from '../components/privateRoute'
import CHRApp from '../components/app'
import PersonalInfoApp from '../components/app/personalInfo'
import NotFoundPage from './404'

import LandingApp from '../components/app/landingApp'

const App = () => {
  return (
    <Router basepath={'/app'}>
      <LandingApp path="/" />
      <PrivateRoute component={CHRApp} path={'/user'} />
      <PrivateRoute component={PersonalInfoApp} path="/personal-info-app" />
      <NotFoundPage default withLayout={false} />
    </Router>
  )
}

export default App

import * as React from 'react'
import { navigate } from 'gatsby'
import { useAppState } from '../../../appState'
import { generateTrackingLink } from '../../../../utils/brandsHelper'
import CHRSpinner from '../../spinner'
import Button from '../../button'
import { styles } from './styles'

interface BrandItemProps {
  src: string
  brandName: string
  commission: string
}

interface HomeAppProps {
  trendingBrands: BrandItemProps[]
}

const HomeApp = ({ trendingBrands }: HomeAppProps) => {
  const [appState] = useAppState()
  const isSkipped = window.localStorage.getItem('howItWorksStatus') !== 'done'
  const classes = styles()

  if (!trendingBrands.length) return <CHRSpinner />

  return (
    <>
      <div className={classes.topBrandsContainer}>
        {isSkipped ? (
          <div>
            <h3 className={classes.topBrandsTitle}>how chirpyest works</h3>
            <p className={classes.howItWorksText}>
              earn money for shopping for yourself and for sharing your favorite
              products with friends!
            </p>
            <Button
              onClick={() => navigate('/app?howItWorks=true')}
              customStyle={classes.hoItWorkButton}
              customTextStyle={classes.buttonText}
              label="how it works"
            />
          </div>
        ) : (
          <>
            <h3 className={classes.topBrandsTitle}>our picks</h3>

            <div className={classes.topBrandsItems}>
              {trendingBrands.slice(0, 3).map(brand => (
                <a
                  className={classes.topBrandItem}
                  href={generateTrackingLink(
                    brand,
                    {
                      userId: appState.userId,
                      userName: appState.username,
                    },
                    '',
                    '',
                    Boolean(appState.userId)
                  )}
                  target="_blank"
                >
                  <img src={brand.src} className={classes.topBrandImage} />
                  <p className={classes.topBrandCashBack}>
                    {brand.commission} cash back
                  </p>
                </a>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={classes.topBrandsContainer}>
        <h3 className={classes.topBrandsTitle}>trending</h3>
        {trendingBrands.slice(3).map(brand => {
          const { brandName, commission } = brand
          return (
            <div className={classes.shopItem} key={brandName}>
              <div>
                <p className={classes.brandName}>{brandName}</p>
                <p className={classes.cashBack}>
                  <strong>{commission}</strong> cash back
                </p>
              </div>
              <a
                href={generateTrackingLink(
                  brand,
                  {
                    userId: appState.userId,
                    userName: appState.username,
                  },
                  '',
                  '',
                  Boolean(appState.userId)
                )}
                className={classes.shopLink}
                target="_blank"
              >
                shop
              </a>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default HomeApp

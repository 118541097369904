import * as React from 'react'
import chrLogo from '../../assets/images/chirpyest-logo.svg'
import chirpyestTextLogo from '../../assets/images/chirpyest-text-logo.svg'
import { styles } from './styles'

const OnboardingHeader = () => {
    const classes = styles();
    return (
        <div className={classes.wrapper}>
            <img src={chrLogo} className={classes.logo}/>
            <img src={chirpyestTextLogo} className={classes.textLogo}/>
            <p className={classes.headerText}>shop. share. earn cash back.</p>
        </div>
    )
}

export default OnboardingHeader

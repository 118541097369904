import React, { useState, useEffect, useCallback } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import i18n from '../../i18n'
import { I18nextProvider } from 'react-i18next'
import { Typography } from '@material-ui/core'
import SEO from '../../seo'
import OnboardingHeader from '../../onboardingHeader'
import JoinModal from '../joinApp'
import SignInModal from '../../app/signInApp'

import Button from '../../button'
import CHRSpinner from '../../spinner'
import WelcomeApp from '../../app/welcomeApp'
import { isUserAuthenticated } from '../../../../utils/isUserAuthenticated'
import chirpyestTextLogo from '../../../assets/images/chirpyest-text-logo.svg'
import chrLogo from '../../../assets/images/chirpyest.svg'
import onboarding1 from '../../../assets/images/onboarding-1.png'
import onboarding2 from '../../../assets/images/onboarding-2.png'
import onboarding3 from '../../../assets/images/onboarding-3.png'
import onboarding4 from '../../../assets/images/onboarding-4.png'
import { styles } from './styles'

interface OnboardingScreenProps {
  title?: string
  text?: string
  image?: string
  video?: string
  isLastScreen: boolean
  handleNext: Function
}

const LoadingScreen = () => {
  const classes = styles()
  return (
    <div className={classes.loadingScreenWrapper}>
      <img src={chrLogo} className={classes.logo} alt="Chirpyest Logo" />
      <img
        src={chirpyestTextLogo}
        alt="Chirpyest"
        className={classes.textLogo}
      />
      <p>shop. share. earn cash back.</p>
    </div>
  )
}

const OnboardingScreen = ({
  title,
  text,
  image,
  video,
  isLastScreen,
  handleNext,
}: OnboardingScreenProps) => {
  const classes = styles()
  const { search } = useLocation()
  const { howItWorks: isHowItWorksRedirect } = parse(search)
  const [isSignInModal, setIsSignInModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isNew, setIsNew] = useState(false)

  useEffect(() => {
    if (isHowItWorksRedirect) {
      setIsLoading(false)
    } else {
      isUserAuthenticated()
        .then(res => {
          const howItWorksStatus = window.localStorage.getItem(
            'howItWorksStatus'
          )
          if (
            res &&
            (howItWorksStatus === 'done' || howItWorksStatus === 'skipped')
          )
            navigate('/app/user')
          else if (howItWorksStatus === 'skipped') {
            setIsLoading(false)
          } else if (!howItWorksStatus) {
            setIsNew(true)
            window.localStorage.setItem('howItWorksStatus', 'skipped')
            setIsLoading(false)
          } else {
            setIsSignInModal(true)
            setIsLoading(false)
          }
        })
        .catch(() => setIsLoading(false))
    }
  }, [])

  const closeSignInModal = useCallback(() => {
    setIsSignInModal(false)
  }, [])

  const handleFinish = () => {
    window.localStorage.setItem('howItWorksStatus', 'done')
    isUserAuthenticated().then(res => {
      if (!res) setIsSignInModal(true)
      else navigate('/app/user')
    })
  }

  const handleSkip = () => {
    if (window.localStorage.getItem('howItWorksStatus') !== 'done') {
      window.localStorage.setItem('howItWorksStatus', 'skipped')
    }
    isUserAuthenticated().then(res => {
      if (!res) setIsSignInModal(true)
      else navigate('/app/user')
    })
  }

  if (isLoading) return <CHRSpinner />

  if (isNew) return <WelcomeApp setIsNew={setIsNew} />
  return (
    <>
      <SEO title="Chirpyest App" />
      <I18nextProvider i18n={i18n}>
        <JoinModal handleClose={closeSignInModal} openStatus={isSignInModal} />
      </I18nextProvider>
      {isSignInModal ? (
        <CHRSpinner />
      ) : (
        <div className={classes.screenWrapper}>
          <OnboardingHeader />
          <div className={classes.content}>
            {image && <img src={image} alt="" className={classes.media} />}
            {video && (
              <video
                playsInline
                loop
                autoPlay
                muted
                preload="none"
                className={classes.media}
              >
                <source src={video} type="video/mp4" />
              </video>
            )}
            <h2 className={classes.slideTitle}>{title}</h2>
            <p className={classes.slideText}>{text}</p>
          </div>
          <div className={classes.callToAction}>
            <Button
              onClick={isLastScreen ? handleFinish : handleNext}
              customStyle={classes.button}
              customTextStyle={classes.buttonText}
              label={isLastScreen ? 'done!' : 'next'}
            />
            {!isLastScreen && (
              <div onClick={handleSkip}>
                <Typography variant="body1" className={classes.skip}>
                  skip all
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const screens = [
  {
    title: 'earn cash back shopping on mobile',
    text:
      'use our mobile extension for safari and earn cash back from our partner brands',
    image: onboarding1,
  },
  {
    title: 'easily see which brands offer the best cash back',
    text:
      'scroll through our brands in the app and see who’s offering the best cash back',
    video:
      'https://dl.dropboxusercontent.com/sh/kgzyvpvjy64dqxb/AABOopwyW01731s_eMqUEtgQa/chirpyest_7_easily%20see%20which%20brands%20offer%20the%20best%20cash%20back_v3.mp4?dl=0',
  },
  {
    title: 'share + earn!',
    text:
      'create a shareable link directly from the safari extension or from the app and earn cash back if someone shops from it',
    image: onboarding2,
  },
  {
    title: 'create & curate your own online store',
    text:
      'create a shopping board of items from partner brands using the extension or app and earn cash back when people shop it',
    image: onboarding3,
  },
  {
    title: 'track your earnings and monthly payouts',
    text:
      'use the app to track your earnings and every month we automatically send you your cash back via Venmo or Paypal',
    image: onboarding4,
  },
]

const App = () => {
  const [screenNumber, setScreenNumber] = useState<number>(0)
  const location = useLocation()
  const [showSplash, setShowSplash] = useState<boolean>(
    location?.search ? false : true
  )

  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false)
    }, 2000)
  }, [])

  const handleNextScreen = () => {
    setScreenNumber((prev: number) => prev + 1)
  }

  if (showSplash) {
    return <LoadingScreen />
  }

  return (
    <>
      <OnboardingScreen
        title={screens[screenNumber].title}
        text={screens[screenNumber].text}
        image={screens[screenNumber].image}
        video={screens[screenNumber].video}
        isLastScreen={screens.length - 1 === screenNumber}
        handleNext={handleNextScreen}
      />
    </>
  )
}

export default App

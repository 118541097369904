import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  input: {
    display: 'block',
    backgroundColor: `${COLORS.whiteSmoke} !important`,
    borderRadius: 27,
    border: 'none',
    padding: 20,
    width: '250px',
    height: '40px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    letterSpacing: 1.2,
    fontSize: '0.75rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    outline: 'none',
    '&::placeholder': {
      color: COLORS.black,
      fontFamily: FONTS.Graphik.GraphikRegular,
    },
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '0.75rem',
    },
  },
  error: {
    borderColor: COLORS.dangerRed,
    borderWidth: 1,
  },
  disabled: {
    background: COLORS.whiteSmoke,
    border: 'none',
    cursor: 'not-allowed',
    pointerEvents: 'none',
    color: COLORS.ashGrey,
  },
}))

import React, { useState, useEffect, useRef } from 'react'
import {
  Typography,
  Grid,
  Box,
  Card as MUIcard,
  CircularProgress,
  CardMedia,
  CardContent,
} from '@material-ui/core'
import axios from '../../../axios'
import { baseURL, COLORS, ENDPOINTS } from '../../../constants'
import { checkIfSubmittable } from '../../../../utils/validation'
import CHRInput from '../../input'
import Button from '../../button'
import { useAppState } from '../../../appState'
import CHRImage from '../../trendingCard/CHRImage'
import CHRSpinner from '../../spinner'
import { tracker } from '../../../systemLogs'
import { TrackingCase } from '../../../../utils/trackingCases'
import AddToShoppingBoardModal from '../../addToShoppingBoardModal'
import {
  generateFacebookSharelink,
  generateEmailSharelink,
  generateSmsSharelink,
  generateTwitterSharelink,
} from '../../../../utils/generateSocialShareLinks'
import clipboard from '../../../assets/images/clipboard.svg'
import emailIcon from '../../../assets/images/dark-email.svg'
import pinterest from '../../../assets/images/pinterest.svg'
import facebookIcon from '../../../assets/images/facebook.svg'
import twiterIcon from '../../../assets/images/twitter.svg'
import smsIcon from '../../../assets/images/sms.svg'
import errorImg from '../../../assets/images/error-image.svg'

import { styles } from './styles'

const ShareLinkApp = ({ boardItems, shareData, handleShareData }: any) => {
  const classes = styles()
  const [appState, dispatch] = useAppState()
  const textAreaRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState({
    image: shareData.image || '',
    loading: false,
  })
  const [linkToConvert, setLinkToConvert] = useState(
    shareData.originalLink || ''
  )
  const [isAddProductOpen, setIsAddProductOpen] = useState(false)
  const [currentMessage, setCurrentMessage] = useState({
    text: '',
    error: false,
  })
  const [shortenedLink, setShortenedLink] = useState({
    value: shareData.shortLink || '',
    commission: shareData.commission || '',
    isLoading: false,
    trackingLink: shareData.trackingLink || '',
  })
  const [boardInfo, setBoardInfo] = useState(boardItems)
  const [igImg, setIgImg] = useState(null)
  const [loadingImg, setLoadingImg] = useState(true)
  const [refreshItems, setRefreshItems] = useState(false)

  const currentMessageStyle = currentMessage.error
    ? classes.errorMsg
    : classes.infoMsg

  useEffect(() => {
    ;(async () => {
      if (product.type === 'instagram') {
        try {
          const {
            data: { data },
          } = await axios.get(
            `/api/v1/ig?igUrl=${encodeURIComponent(
              product.data.link + 'media/?size=l'
            )}`
          )

          setIgImg(data)
          setLoadingImg(false)
        } catch (err) {
          setIgImg(errorImg)
          setLoadingImg(false)
        }
      }
    })()
  }, [])

  useEffect(() => {
    const getBoardItems = async () => {
      try {
        const userName = appState.userProfile.userName
        const endpoint = ENDPOINTS.shoppingBoardNonUser.replace(':id', userName)
        const {
          data: {
            data: { shoppingBoardItems },
          },
        } = await axios(endpoint)
        setBoardInfo({
          isLoading: false,
          boardItems: shoppingBoardItems,
        })
      } catch (e) {
        setBoardInfo({
          isLoading: false,
          boardItems: [],
        })
      }
    }
    getBoardItems()
  }, [refreshItems])

  const convertURLToStandardFormat = (url: any) => {
    if (url.includes('https://') || url.includes('http://')) {
      return url
    } else {
      return 'https://' + url
    }
  }

  const handleCreateLink = () => {
    setCurrentMessage({ text: '' })
    if (linkToConvert && linkToConvert.length > 0) {
      setShortenedLink({ isLoading: true })
      const targetLink = convertURLToStandardFormat(linkToConvert)
      try {
        let refinedURL
        let productURL = new URL(targetLink)
        if (
          productURL.host === 'www.containerstore.com' &&
          productURL.search.includes('q=')
        ) {
          productURL.search.split('&').forEach(param => {
            if (param.includes('productId')) {
              refinedURL = productURL.origin + productURL.pathname + '?' + param
            }
          })
        } else {
          refinedURL = targetLink
          refinedURL = linkToConvert
        }
        ;(async () => {
          const shortenedValue = await axios.post(
            `${baseURL}/${ENDPOINTS.shortenLink}`,
            { url: refinedURL }
          )
          const media = await getPinterestMedia()
          const { data } = shortenedValue.data
          if (data && data.shortUrl?.length > 0) {
            setShortenedLink({
              trackingLink: data.trackingLink,
              value: data.shortUrl,
              commission: data.commission,
            })
            handleShareData({
              shortLink: data.shortUrl,
              originalLink: refinedURL,
              commission: data.commission,
              trackingLink: data.trackingLink,
              image: media,
            })
          }
          setShortenedLink((prevState: any) => ({
            ...prevState,
            isLoading: false,
          }))
        })().catch(err => {
          if (
            err?.response?.data?.message ===
            'Sorry, this link is not in our network'
          ) {
            setCurrentMessage({
              text: "sorry, we don't have your url in our records.",
              error: true,
            })
          } else if (
            err?.response?.data?.message === 'url must be a valid URL'
          ) {
            setCurrentMessage({
              text: '"url" must be a valid url',
              error: true,
            })
          }
          setShortenedLink({ value: '', trackingLink: '' })
        })
      } catch (error) {
        setShortenedLink({ isLoading: false })
        setCurrentMessage({ text: 'please try again.', error: true })
      }
    } else {
      setCurrentMessage({
        text: 'please insert link to be converted.',
        error: true,
      })
    }
  }

  const handleAddToShoppingBoard = () => {
    setLoading(true)
    setCurrentMessage({ text: '' })
    if (linkToConvert && linkToConvert.length > 0) {
      setLoading(true)

      try {
        tracker.track(
          TrackingCase.UserTracking,
          `POST ${ENDPOINTS.addItemToShoppingBoard}`,
          { location: 'CreateLinkPage', linkToConvert }
        )
        ;(async () => {
          const result = await axios.post(ENDPOINTS.addItemToShoppingBoard, {
            link: linkToConvert,
          })
          const { data } = result.data
          if (data) {
            dispatch({
              type: 'UPDATE_AFTER_ADDING_TO_SHOPPING_BOARD',
              afterAddShoppingItem: true,
            })
            setCurrentMessage({
              text: 'product added to your shopping board',
              error: false,
            })
            setRefreshItems((prev: boolean) => !prev)
          }
          setLoading(false)
        })().catch(err => {
          setLoading(false)
          const resErr = err.response.data.message
          if (err.response && err.response.data) {
            if ('Shopping Board not found!' === resErr) {
              setCurrentMessage({
                text: 'board not found!',
                error: true,
              })
            }

            if ('product not found!' === resErr) {
              return setIsAddProductOpen(true)
            }

            const errMsg =
              'Brand not found!' === resErr ? 'brand not found' : resErr

            setCurrentMessage({
              text: errMsg,
              error: true,
            })
          }
        })
      } catch (error) {
        setLoading(false)
        setCurrentMessage({ text: 'please try again.', error: true })
      }
    } else {
      setLoading(false)
      setCurrentMessage({
        text: 'please insert link to be converted.',
        error: true,
      })
    }
  }

  const handleSMSButtonClick = () => {
    window.open(
      `sms:?&body=Sharing this with you! You should totally get it!  ${shortenedLink.value}`,
      '_self'
    )
  }

  const handleConvertAnotherLink = () => {
    setCurrentMessage({ text: '' })
    setLinkToConvert('')
    setShortenedLink({ value: '', trackingLink: '' })
    window.history.pushState('', '', `?`)
  }

  const copyToClipboard = (event: any) => {
    if (textAreaRef && textAreaRef?.current !== null) {
      textAreaRef?.current?.select()
      document.execCommand('copy')
      event?.target?.focus()
      const successMessage = 'copied to clipboard!'
      setCurrentMessage({ text: successMessage, error: false })
    } else {
      const failMessage = 'could not select text.'
      setCurrentMessage({ text: failMessage, error: false })
    }
  }

  const getPinterestMedia = async () => {
    try {
      const res = await axios.post(ENDPOINTS.parseProduct, {
        link: linkToConvert,
      })
      setProduct({
        image: res.data.data.imageUrl,
        loading: false,
      })
      return res.data.data.imageUrl
    } catch (err) {
      setProduct({
        image: 'https://chirpyest.com/src/favicon-512x512.png',
        loading: false,
      })
      return 'https://chirpyest.com/src/favicon-512x512.png'
    }
  }

  const handleShareOnPinterest = () => {
    return `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(shortenedLink.trackingLink)}`
  }

  return (
    <div className={classes.container}>
      <div>
        <AddToShoppingBoardModal
          open={isAddProductOpen}
          handleClose={() => setIsAddProductOpen(false)}
          link={linkToConvert}
        />
        {loading && (
          <div className={classes.loading}>
            <CircularProgress style={{ color: '#000' }} size={40} />
          </div>
        )}
        <h1 className={classes.title}>
          {shortenedLink.value ? 'good work!' : 'create share link'}
        </h1>
        <p className={classes.text}>
          {shortenedLink.value
            ? shortenedLink.commission
              ? `you can earn up to ${shortenedLink.commission} cash back if people shop this link!`
              : 'you can earn cash back if people shop this link!'
            : 'paste a link from one of our affiliated brand sites to add it to your shopping board or convert it to a chirpyest share link and earn cash back if people shop through it!'}
        </p>
        {currentMessage.text && (
          <Typography
            variant="subtitle1"
            component="p"
            className={`${classes.alertText} ${currentMessageStyle}`}
          >
            {currentMessage.text}
          </Typography>
        )}
        <CHRInput
          placeholder="paste link"
          value={linkToConvert}
          onChange={(event: any) => setLinkToConvert(event.target.value)}
          onKeyPress={(event: any) => {
            shortenedLink.value === '' &&
              checkIfSubmittable(event, handleCreateLink)
          }}
          disabled={shortenedLink.value ? true : false}
          type="url"
          customStyles={classes.linkInput}
        />
        {shortenedLink.value && (
          <>
            <Box mt={2.5}>
              <div
                className={`${classes.inputContainer} ${classes.convertedLink}`}
              >
                <CHRInput
                  placeholder=""
                  value={shortenedLink.value}
                  onChange={() => shortenedLink.value}
                  elementRef={textAreaRef}
                />
                {document.queryCommandSupported('copy') && (
                  <Button
                    customStyle={classes.copyBtn}
                    customTextStyle={classes.copyTextBtn}
                    label="copy link"
                    onClick={copyToClipboard}
                  />
                )}
              </div>
            </Box>
            <Box mt={2.5}>
              <div className={classes.inputContainer}>
                <a
                  className={classes.shopLinkBtn}
                  href={`${shortenedLink.value}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  shop link
                </a>
              </div>
            </Box>
            <Box mt={2.5}>
              <div className={classes.inputContainer}>
                <button
                  label="convert another link"
                  onClick={handleConvertAnotherLink}
                  className={classes.convertAnotherLinkBtn}
                >
                  convert another link
                </button>
              </div>
            </Box>
          </>
        )}

        {!shortenedLink.value && (
          <div className={classes.buttons}>
            <Button
              onClick={handleCreateLink}
              customStyle={classes.button}
              customTextStyle={classes.buttonText}
              label="create share link"
              isSubmitting={shortenedLink.isLoading}
              disabled={shortenedLink.isLoading}
            />
            <Button
              onClick={handleAddToShoppingBoard}
              customStyle={classes.button}
              customTextStyle={classes.buttonText}
              label="add to shopping board"
            />
          </div>
        )}
        {shortenedLink.value && (
          <>
            <Box className={classes.createLinkBtns} mt={2.5}>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item xs={6}>
                  <button
                    className={classes.boardBtn}
                    onClick={handleAddToShoppingBoard}
                  >
                    <img src={clipboard} alt="clipboard" />
                    <span className={classes.socialText}>
                      add to my shopping board
                    </span>
                  </button>
                </Grid>
                <Grid item xs={6}>
                  <div
                    className={`${classes.inputContainer} ${classes.socialButton}`}
                  >
                    <a
                      href={generateEmailSharelink(
                        shortenedLink.value,
                        'Here is one of my favorites. You should totally get it!',
                        'Shopping find - this is perfect for you'
                      )}
                      target="_blank"
                    >
                      <img
                        src={emailIcon}
                        alt="email"
                        className={classes.socialIcon}
                      />
                      <span className={classes.socialText}>share on email</span>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    className={`${classes.inputContainer} ${classes.socialButton}`}
                  >
                    <a
                      href={generateSmsSharelink(
                        shortenedLink.value,
                        'Sharing this with you! You should totally get it! '
                      )}
                      target="_blank"
                    >
                      <img
                        src={smsIcon}
                        alt="email"
                        className={classes.socialIcon}
                      />
                      <span className={classes.socialText}>share on sms</span>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    className={`${classes.inputContainer} ${classes.socialButton}`}
                  >
                    <a
                      href={generateFacebookSharelink(
                        shortenedLink.value,
                        'Top shopping find! Sharing one of my favorites! Shop away! '
                      )}
                      target="_blank"
                    >
                      <img
                        src={facebookIcon}
                        alt="facebook"
                        className={classes.socialIcon}
                      />
                      <span className={classes.socialText}>
                        share on facebook
                      </span>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    className={`${classes.inputContainer} ${classes.socialButton}`}
                  >
                    <a
                      href={generateTwitterSharelink(
                        shortenedLink.value,
                        'Top shopping find! Sharing one of my favorites! Shop away! '
                      )}
                      target="_blank"
                    >
                      <img
                        src={twiterIcon}
                        alt="twitter"
                        className={classes.socialIcon}
                      />
                      <span className={classes.socialText}>
                        share on twitter
                      </span>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    className={`${classes.inputContainer} ${classes.socialButton}`}
                  >
                    <a href={handleShareOnPinterest()} target="_blank">
                      <img
                        src={pinterest}
                        alt="pinterest"
                        className={classes.socialIcon}
                      />
                      <span className={classes.socialText}>pinterest</span>
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </div>
      <h2 className={classes.title}>your shopping board</h2>
      <>
        {boardInfo.isLoading ? (
          <CHRSpinner />
        ) : boardInfo.boardItems && boardInfo.boardItems?.length ? (
          <>
            <p className={classes.text}>
              keep adding to your shopping board. share it with your friends and
              earn when they shop what you've curated
            </p>
            <Box mt={2.5}>
              <Grid container spacing={1}>
                {boardInfo.boardItems.map((product: any) => (
                  <Grid
                    item
                    md={4}
                    sm={4}
                    xs={6}
                    style={{
                      userSelect: 'none',
                      paddingTop: 0,
                      paddingBottom: 40,
                    }}
                    className={classes.SBCard}
                  >
                    <MUIcard
                      className={classes.card}
                      style={{ position: 'relative' }}
                    >
                      <div className={classes.cardArea}>
                        <div className={classes.cardMediaWrapper}>
                          <div className={classes.cardMediaWrapperInner}>
                            {loadingImg && product.type === 'instagram' && (
                              <div className={classes.loading}>
                                <CircularProgress
                                  style={{ color: COLORS.black }}
                                  size={20}
                                />
                              </div>
                            )}
                            {product.type === 'product' ? (
                              <CHRImage
                                errStyle={classes.errImage}
                                alt={product.data.name}
                                src={
                                  product.data.imageUrl || product.data.base64
                                }
                                title={product.data.name}
                                className={classes.cardMedia}
                              />
                            ) : (
                              <CardMedia
                                component="img"
                                alt={product.data.name}
                                image={igImg}
                                title={product.data.name}
                                className={classes.cardMedia}
                                style={{
                                  height: 'auto',
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <CardContent className={classes.cardContent}>
                          {product.type === 'product' ? (
                            <>
                              <div className={classes.boxInfo}>
                                <Typography
                                  component="h2"
                                  className={classes.cardName}
                                >
                                  {product.data.name}
                                </Typography>
                                <Typography
                                  component="p"
                                  className={classes.cardBrandName}
                                >
                                  {product.data.brandName}
                                </Typography>
                              </div>
                              <div className={classes.boxPrices}>
                                <div className={classes.saleBox}>
                                  {Number(product.data.price?.slice(1)) <
                                  Number(
                                    product.data.originalPrice?.slice(1)
                                  ) ? (
                                    <div>
                                      <Typography
                                        component="p"
                                        className={classes.cardPrice}
                                        style={{
                                          textDecoration: 'line-through',
                                        }}
                                      >
                                        {product.data.originalPrice}
                                      </Typography>
                                      <Typography
                                        component="p"
                                        className={classes.cardPrice}
                                      >
                                        {product.data.price}
                                      </Typography>
                                    </div>
                                  ) : (
                                    <Typography
                                      component="p"
                                      className={classes.cardPrice}
                                    >
                                      {product.data.price}
                                    </Typography>
                                  )}
                                </div>
                                {product.data.cashBack && (
                                  <Typography
                                    component="p"
                                    className={classes.cashPercentage}
                                  >
                                    (<strong>{product.data.cashBack}</strong>{' '}
                                    cash back)
                                  </Typography>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className={classes.boxInfo}>
                                <div className={classes.cardIngMediaWrap}>
                                  <CHRImage
                                    errStyle={classes.errIgImage}
                                    src={product?.data?.profile_image || ''}
                                    title={product.data.username}
                                    alt={product.data.username}
                                    className={`${classes.cardMedia} ${classes.cardIngMediaWrap}`}
                                  />
                                </div>
                                <Typography
                                  component="p"
                                  className={classes.cardIngText}
                                >
                                  @{product.data.username}
                                </Typography>
                              </div>
                            </>
                          )}
                        </CardContent>
                      </div>
                    </MUIcard>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <p className={classes.text}>
              your shopping board is empty. Use our extension to add items and
              start earning cash back when anyone shops it!
            </p>
            <a
              className={classes.viewBtn}
              href={`https://www.chirpyest.com/s/${appState.userProfile.userName}`}
              target='_blank'
            >
              view board
            </a>
            <Box mt={2.5}>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <div className={classes.grayCard}></div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <div className={classes.grayCard}></div>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </>
    </div>
  )
}

export default ShareLinkApp

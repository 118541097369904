import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import Auth from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'
import { navigate, Link } from 'gatsby'
import axios from '../../../axios'
import { useAppState } from '../../../appState'
import { ENDPOINTS } from '../../../constants'
import CHROutlinedButton from '../../outlinedButton'
import Button from '../../button'
import arrow from '../../../assets/images/brands-arrow.svg'
import birdAvatar from '../../../assets/images/bird-avatar.png'
import facebookIcon from '../../../assets/images/facebook.svg'
import instagramIcon from '../../../assets/images/instagram.svg'
import pinterestIcon from '../../../assets/images/pinterest.svg'
import tiktokIcon from '../../../assets/images/tiktok.svg'
import WebsiteIcon from '@material-ui/icons/Language'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { SOCIAL_MEDIA_LINKS } from '../../../constants'
import awsConfig from '../../../aws-exports'

import { styles } from './styles'

awsConfig.Auth.oauth.redirectSignOut =
  process.env.GATSBY_OAUTH_REDIRECT_SIGN_OUT_APP

awsConfig.Auth.oauth.redirectSignIn =
  process.env.GATSBY_OAUTH_REDIRECT_SIGN_IN_APP

Amplify.configure(awsConfig)
Auth.configure(awsConfig)

const AccountApp = () => {
  const classes = styles()
  const [appState, dispatch] = useAppState()
  const [userInfo, setUserInfo] = useState({})
  const [isCopied, setIsCopied] = useState(false)
  const userJoinLink = `${new URL(process.env.GATSBY_SITE_URL).host.replace(
    'www.',
    ''
  )}/join/${appState.username}-${appState.userId}`

  useEffect(() => {
    let userName = appState.username
    let endpoint = ENDPOINTS.shoppingBoardNonUser.replace(':id', userName)
    const getUserInfo = async () => {
      const {
        data: { data },
      } = await axios.get(`${endpoint}`)
      setUserInfo(data.userInfo)
    }
    getUserInfo()
  }, [])

  const handleSignOut = async () => {
    dispatch({ type: 'SIGN_OUT' })
    navigate('/app?signOut=true')
    window.localStorage.removeItem('user_id')
    window.localStorage.removeItem('movedItems')
    window.localStorage.removeItem('ig_token')
    window.localStorage.removeItem('referralId')
    window.localStorage.removeItem('profile')

    Auth.signOut()
  }

  const copyToClipboard = () => {
    const textField = document.createElement('textarea')
    textField.innerText = userJoinLink
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    setIsCopied(true)
    textField.remove()
  }

  return (
    <div>
      <div className={classes.greetingWrapper}>
        <p className={classes.greeting}>hi {appState.userProfile.userName}</p>
        <CHROutlinedButton
          label="sign out"
          onClick={handleSignOut}
          customStyle={classes.signOut}
        />
      </div>
      <div className={classes.infoWrapper}>
        <img
          src={appState.userProfile.imageUrl || birdAvatar}
          alt="profile"
          className={classes.profileImg}
        />
        <div className={classes.textWrapper}>
          <div>
            {appState.userProfile.fullName && (
              <h4 className={classes.userName}>
                {appState.userProfile.fullName}
              </h4>
            )}
            {userInfo.shortBio && (
              <p className={classes.text}>{userInfo.shortBio}</p>
            )}
          </div>
          {appState?.userProfile?.financialData?.lastClosedOut ? (
            <p className={classes.text}>
              $
              {Number(appState.userProfile.financialData.lastClosedOut).toFixed(
                2
              )}{' '}
              cash back earned this month
            </p>
          ) : null}
        </div>
      </div>
      <div className={classes.linksWrapper}>
        <a
          className={classes.link}
          href="https://www.chirpyest.com/user/dashboard#cashback"
          target="_blank"
        >
          <h2 className={classes.linkText}>cash back</h2>
          <img src={arrow} alt="arrow" className={classes.arrow} />
        </a>
        <a
          className={classes.link}
          href="https://www.chirpyest.com/user/dashboard/#paymentInfo"
          target="_blank"
        >
          <h2 className={classes.linkText}>payment info</h2>
          <img src={arrow} alt="arrow" className={classes.arrow} />
        </a>
        <a
          className={classes.link}
          href="https://www.chirpyest.com/user/dashboard/#settings"
          target="_blank"
        >
          <h2 className={classes.linkText}>edit profile</h2>
          <img src={arrow} alt="arrow" className={classes.arrow} />
        </a>
        <p className={classes.link}>
          <h2 className={classes.linkText}>app settings & notifications</h2>
          <img src={arrow} alt="arrow" className={classes.arrow} />
        </p>
        <p className={classes.link}>
          <h2 className={classes.linkText}>upgrade – Coming soon</h2>
          <img src={arrow} alt="arrow" className={classes.arrow} />
        </p>
      </div>
      <Box ml={2.5} mr={2.5}>
        <h3 className={classes.shareTitle}>share chirpyest</h3>
        <div className={classes.shareWrapper}>
          <p className={classes.shareText}>
            <span className={classes.boldText}>
              refer a friend, get $10! use join link
            </span>{' '}
            <span className={classes.underline}>{userJoinLink}</span>
          </p>
          {document.queryCommandSupported('copy') && (
            <Button
              onClick={copyToClipboard}
              customStyle={classes.button}
              customTextStyle={classes.buttonText}
              label={isCopied ? 'copied' : 'copy link'}
            />
          )}
        </div>
      </Box>
      <Box>
        <ul className={classes.socialMediaList}>
          <li className={classes.socialListItem}>
            <a
              href="https://www.chirpyest.com"
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <WebsiteIcon color="#000" />
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href={SOCIAL_MEDIA_LINKS.facebook}
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <img src={facebookIcon} alt="facebook" width="25" height="25" />
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href={SOCIAL_MEDIA_LINKS.instargram}
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <img src={instagramIcon} alt="instagram" width="25" height="25" />
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href={SOCIAL_MEDIA_LINKS.tiktok}
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <img src={tiktokIcon} alt="tiktok" width="25" height="25" />
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href={SOCIAL_MEDIA_LINKS.pinterest}
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <img src={pinterestIcon} alt="pinterest" width="25" height="25" />
            </a>
          </li>

          <li className={classes.socialListItem}>
            <a
              href="https://www.chirpyest.com/company#contact-us"
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <MailOutlineIcon />
            </a>
          </li>
        </ul>
      </Box>
    </div>
  )
}

export default AccountApp
